@import "../themes.scss";

.setting-page {
  .page-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
  }

  .text-field {
    width: 100%;
    border-radius: 10px;
    font-family: $font-title;
    background-color: #fff;
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-title;
      border-radius: 10px;
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
  }

  .body-container {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    // padding: 0px 0px 20px 0px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }
    .btn-setting {
      width: 100%;
      height: 100px;
      background-color: #fff;
      // background: linear-gradient(
      //   45deg,
      //   rgb(242, 244, 251) 0%,
      //   rgb(210, 209, 243) 100%
      // );
      color: $primary-color;
      border-radius: 10px;
      text-transform: none;

      .btn-icon {
        font-size: 30px;
        color: $background-menu-active;
      }

      .btn-setting-title {
        font-size: $font-size-body;
        text-align: left;
        font-weight: bold;
      }
      .btn-setting-des {
        font-size: 13px;
        text-align: left;
      }
    }
  }
}
