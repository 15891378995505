@import "../themes.scss";

.request-card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  height: wrap-content;

  .card-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;

    .request-card-header {
      margin-top: 4px;
      background-color: #f0f1fc;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid $primary-color;
    }

    .request-card-warning {
      margin-top: 4px;
      background-color: #fbf9d1;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid #ddd200;
    }
    .request-card-take-leave-record {
      margin-top: 4px;
      background-color: #e7f6e6;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid #01ba36;
    }

    .request-card-request-type {
      font-size: 13px;
      font-weight: bold;
      font-family: $font-title;
    }



    .request-card-reason {
      font-size: 13px;
      font-family: $font-title;
    }

    .request-card-item {
      font-family: $font-title !important;
      font-weight: 100 !important;
      font-size: 13px;
    }

    .request-card-deny {
      font-family: $font-title !important;
      font-weight: 100 !important;
      color: red;
      font-size: 13px;
    }

    .take-leave-card-mobile {
      color: #023f88;
      font-size: 14px;
      font-weight: bold;
      background-color: #ffff;
      border-radius: 4px;
      width: 100%;
      padding: 20px 20px;
      display: flex;
      justify-content: left;
    }

    .take-leave-card-reason {
      color: black !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      font-family: Siemreap !important;
      padding-bottom: 15px !important;
    }

    .btn-dennie {
      background-color: $background-delete !important;
      color: #fff;
      border-radius: 10px;
      text-transform: none;
      padding: 4px 10px;
    }
    .btn-approve {
      background-color: $background-create !important;
      color: #fff;
      border-radius: 10px;
      text-transform: none;
      padding: 4px 10px;
    }
  }
}

.page-request-mobile {
  width: 100% !important;
  // background-color: red;
  .tabs-btn {
    width: 100%;
    border-radius: 0px !important;
    background-color: #fff;
    color: $primary-color;
    padding: 10px 0px;
    text-transform: none;
  }
  .tabs-btn-active {
    width: 100%;
    border-radius: 0px !important;
    background-color: $primary-color !important;
    color: #fff;
    padding: 10px 0px;
    text-transform: none;
  }
  .tabs-title {
    font-family: $font-title;
    font-size: $font-size-body;
  }

  .stack-user-container {
    .user-request {
      font-size: $font-size-body;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
    }
    .date-request {
      font-size: 12px;
    }
  }
}
// .create-fixed-btn-container {
//   width: 100% !important;
//   top: auto !important;
//   bottom: 20 !important;
//   right: 20 !important;
//   position: fixed !important;
//   z-index: 200 !important;
//   display: flex !important;
//   justify-content: right;
// }
