@import "../../themes.scss";

.staff-info {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: auto !important;
  }

  .body-container {
    margin-top: 20px;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 30px 20px 30px;

    .btn-create {
      width: 120px;
      padding: 6px 20px;
      text-align: center;
      border-radius: 10px;
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
    }

    .data-title {
      color: $primary-color;
      font-family: $font-title;
      font-size: $font-size-subtitle;
      font-weight: bold;
    }

    .body-title {
      color: $primary-color;
      font-weight: bold;
      font-size: $font-size-body;
      font-family: $font-title;
    }

    .body-text {
      width: 50%;
      color: rgba(0, 0, 0, 0.803);
      font-family: $font-body;
      font-size: $font-size-body;
    }

    .text-field {
      width: 60%;
      background-color: #fff;
      border-radius: 5px;

      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        border-color: #fff;
        background-color: $background-table-active;
        border-radius: 5px;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        color: black;
        border: 1px solid #fff !important;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
        border-color: #fff;
      }

      .MuiInputLabel-root.Mui-focused {
        color: black;
        font-family: $font-body;
      }

      // date picker
      .css-jvf6rl-MuiInputBase-root-MuiOutlinedInput-root {
        background-color: $background-table-active;
      }
    }

    .title-add {
      align-items: center;
      justify-content: space-evenly;
      color: $primary-color;
      font-family: $font-title;
      font-weight: bold;

      .profile {
        cursor: pointer;

        .avater-image {
          width: 140px;
          height: 140px;
        }
      }

      .text-En {
        font-family: $font-title;
        font-size: $font-size-body;
        // font-weight: bold;
        color: #000;
      }
    }
  }
}