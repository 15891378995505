@import "../themes.scss";

.delete-container {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 400px !important;
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }

  .field-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .action-container {
    width: 100%;
    margin: 10px 16px 10px 16px;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-delete {
      background-color: $background-delete !important;
      color: #fff;
      font-family: $font-title;
    }

    .btn-approve {
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
    }
  }
}


.approve-container {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 380px !important;
  }

  .dialog-title {
    font-size: $font-size-body;
    color: $primary-color;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }

  .approve-body-text {
    font-family: $font-title;
    color: $primary-color;
    font-size: 14px;
  }

  .action-container {
    width: 100%;
    margin: 10px 16px 10px 16px;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-delete {
      background-color: $background-delete !important;
      color: #fff;
      font-family: $font-title;
      font-size: 14px;
    }

    .btn-approve {
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
      font-size: 14px;
    }
  }
}
