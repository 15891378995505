@import "../../themes.scss";

.staff-details {
  .link {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }

  .page-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
    text-align: left;
    padding: 0px;
  }

  .btn-update {
    padding: 0px 20px;
    background-color: $background-create !important;
    border-radius: 10px;
    color: #fff;
  }

  .tabs-container {
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 20px;
  }

  .btn-details {
    width: 100%;
    height: 40px;
    font-family: $font-title;
    font-size: $font-size-body;
    text-transform: none;
    text-align: center;
    color: $primary-color;
    background-color: #fff;
  }
  .btn-details-active {
    width: 100%;
    height: 40px;
    font-family: $font-title;
    font-size: $font-size-body;
    text-transform: none;
    text-align: center;
    color: #fff;
    background-color: $background-create !important;
  }
}
