@import "../themes.scss";

.btn-add {
  background-color: #fff;
  color: $primary-color;
  :hover{
    background-color: $primary-color !important;
    color: #fff !important
  }
}
