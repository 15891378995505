@import "../themes.scss";

.page-container {
  width: 100%;
  .link {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
  .page-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
  }

  .btn-add {
    background-color: $background-create !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    font-size: $font-size-body;
  }

  .btn-add-list {
    padding: 4px 10px;
    border-radius: 10px;
    background-color: $background-create !important;
    color: #fff;
  }

  .text-field {
    width: 100%;
    border-radius: 10px;
    font-family: $font-title;
    background-color: #fff;
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-title;
      border-radius: 10px;
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
  }

  // ====================================== document page ================================
  .document-list {
    background-color: #fff !important;
    border-radius: 10px;
    // padding: 10px;
  }

  .btn-print {
    background-color: $background-create !important;
    border-radius: 10px;
    padding: 8px 20px;
    color: #fff;
  }

  .body-container {
    margin-top: 20px;
    width: 100%;
    background-color: $background-table-active;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    .table {
      width: 100%;
      min-width: 700px;

      @include mobile-small {
        width: 310px !important;
      }

      @include mobile {
        width: 370px !important;
      }

      @include mobile-large {
        width: 476px !important;
      }

      .header-row {
        background-color: #fff;
        border-radius: 10px 10px 0px 0px;
        padding: 30px 0px;
        .header-title-start {
          border-radius: 10px 0px 0px 0px;
          padding: 18px 0px 18px 30px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          // position: fixed !important;
          // background-color: #fff;
        }
        .header-title {
          padding: 14px 20px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }
        .khmer-name {
          padding: 14px 20px;
          border: none;
          font-family: $font-body;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }
        .header-title-end {
          border-radius: 0px 10px 0px 0px;
          padding: 18px 30px 18px 15px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }
        .header-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
        }
        .header-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
        }
      }

      .body-row {
        background-color: #fff;
        cursor: pointer;
        .body-cell-start {
          border: none;
          font-size: $font-size-body;
          padding: 8px 0px 8px 30px;
          // position: fixed !important;
          // background-color: #fff;
        }
        .body-cell {
          border: none;
          font-size: $font-size-body;
          padding: 6px 20px;
        }
        .khmer-name {
          border: none;
          font-family: $font-body;
          font-size: $font-size-body;
          padding: 6px 20px;
        }
        .body-cell-end {
          border: none;
          font-size: $font-size-body;
          padding: 8px 30px 8px 0px;

          .body-cell-print {
            background-color: $background-create;
            border-radius: 10px;
            color: #fff;
            font-size: $font-body;
            text-transform: none;
          }
        }
        .cell-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
        .cell-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
      }

      .body-row-gray {
        background-color: $background-table-active;
        cursor: pointer;
        .body-cell-start {
          border: none;
          font-size: $font-size-body;
          padding: 8px 0px 8px 30px;
          // position: fixed !important;
          // background-color: $background-table-active;
        }
        .body-cell {
          border: none;
          font-size: $font-size-body;
          padding: 6px 20px;

          .res-name {
            font-size: $font-size-body;
            font-size: $font-size-body;
          }
        }
        .khmer-name {
          border: none;
          font-family: $font-body;
          font-size: $font-size-body;
          padding: 6px 20px;
        }
        .body-cell-end {
          border: none;
          font-size: $font-size-body;
          padding: 8px 30px 8px 0px;

          .body-cell-print {
            background-color: $background-create;
            border-radius: 10px;
            color: #fff;
            font-size: $font-body;
            text-transform: none;
          }
        }
        .cell-sticky-left {
          position: sticky;
          left: 0;
          background-color: $background-table-active;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
        .cell-sticky-right {
          position: sticky;
          right: 0;
          background-color: $background-table-active;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
      }
    }
  }
}
