@import "../../themes.scss";


.box-container {
  background-color: #ffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 24px;
  border-radius: 10px;
  height: 100%;
  position: relative;


  .box-icon-container {
    border-radius: 10px;
    height: 70px;
    width: 70px;
    opacity: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

  .text-title {
    font-size: 14px;
    opacity: 70%;
    font-family: $font-title;
    font-weight: 800;
  }
  
  .text-main {
    font-size: 38px;
    font-weight: bold;
    opacity: 90%;
  }

  .card-title-center {
    font-size: 16px;
    font-weight: 800;
    opacity: 70%;
    font-family: $font-title;
    text-align: center;
    padding-top: 10px;
  }
}

.table-body-container {
  // margin-top: 20px;
  width: 100%;
  background-color: $background-table-active;
  border-radius: 10px;
  padding: 0px !important;

  .table {
    width: 100%;
    min-width: 700px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .header-row {
      // background-color: #0043df;
      background-color: #198F4A;
      opacity: 80%;
      // border-radius: 10px;
      padding: 0px !important;
      .header-title-start {
        border-radius: 10px 0px 0px 0px;
        padding: 9px 0px 9px 30px;
        border: none;
        // opacity: 70%;
        font-family: $font-title;
        font-size: $font-size-body;
        font-weight: bold;
        color: #fff;
        // color: $primary-color;
        // position: fixed !important;
        // background-color: #fff;
      }
      .header-title {
        padding: 0px !important;
        border: none;
        // opacity: 70%;
        font-family: $font-title;
        font-size: $font-size-body;
        font-weight: bold;
        color: #fff;
        // color: $primary-color;
      }
      .khmer-name {
        padding: 14px 20px;
        border: none;
        font-family: $font-body;
        font-size: $font-size-body;
        font-weight: bold;
        color: $primary-color;
      }
      .header-title-end {
        border-radius: 0px 10px 0px 0px;
        padding: 0px !important;
        // opacity: 70%;
        border: none;
        font-family: $font-title;
        font-size: $font-size-body;
        font-weight: bold;
        color: #fff;
        // color: $primary-color;
      }
      .header-sticky-left {
        position: sticky;
        left: 0;
        background-color: #fff;
      }
      .header-sticky-right {
        position: sticky;
        right: 0;
        background-color: #fff;
      }
    }

    .body-row {
      background-color: #fff;
      cursor: pointer;
      .body-cell-start {
        border: none;
        font-size: $font-size-body;
        padding: 8px 0px 8px 30px;
        // position: fixed !important;
        // background-color: #fff;
      }
      .body-cell {
        border: none;
        font-size: $font-size-body;
        padding: 8px 0px;
      }
      .khmer-name {
        border: none;
        font-family: $font-body;
        font-size: $font-size-body;
        padding: 8px 0px;
      }
      .body-cell-end {
        border: none;
        font-size: $font-size-body;
        padding: 8px 30px 8px 0px;

        .body-cell-print {
          background-color: $background-create;
          border-radius: 10px;
          color: #fff;
          font-size: $font-body;
          text-transform: none;
        }
      }
      .cell-sticky-left {
        position: sticky;
        left: 0;
        background-color: #fff;
        // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
      .cell-sticky-right {
        position: sticky;
        right: 0;
        background-color: #fff;
        // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    }

    .body-row-gray {
      background-color: $background-table-active;
      cursor: pointer;
      .body-cell-start {
        border: none;
        font-size: $font-size-body;
        padding: 8px 0px 8px 30px;
        // position: fixed !important;
        // background-color: $background-table-active;
      }
      .body-cell {
        border: none;
        font-size: $font-size-body;
        padding: 8px 0px;
      }
      .khmer-name {
        border: none;
        font-family: $font-body;
        font-size: $font-size-body;
        padding: 8px 0px;
      }
      .body-cell-end {
        border: none;
        font-size: $font-size-body;
        padding: 8px 30px 8px 0px;

        .body-cell-print{
          background-color: $background-create;
          border-radius: 10px;
          color: #fff;
          font-size: $font-body;
          text-transform: none;
        }
      }
      .cell-sticky-left {
        position: sticky;
        left: 0;
        background-color: $background-table-active;
        // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
      .cell-sticky-right {
        position: sticky;
        right: 0;
        background-color: $background-table-active;
        // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    }
  }
}

