@import "../themes.scss";

.create-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }

  .field-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .avater-image {
    width: 140px;
    height: 140px;
  }

  .button-image {
    width: 180px;
    height: auto;
  }

  // .text-field {
  //   width: 100%;
  //   background-color: #fff;
  //   // border-radius: 10px;
  //   .MuiOutlinedInput-input {
  //     color: black !important;
  //     font-family: $font-body;
  //     border-color: #fff;
  //     background-color: $primary-background;
  //     border-radius: 5px;
  //   }
  //   .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  //     color: black;
  //     border: 1px solid #fff !important;
  //   }
  //   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
  //     color: black;
  //     border-color: #fff;
  //   }
  //   .MuiInputLabel-root.Mui-focused {
  //     color: black;
  //     font-family: $font-body;
  //   }
  //   // date picker
  //   .css-n4ests-MuiInputBase-root-MuiOutlinedInput-root {
  //     background-color: $primary-background;
  //   }
  //   //multiline
  //   .css-jvf6rl-MuiInputBase-root-MuiOutlinedInput-root {
  //     background-color: $primary-background;
  //   }
  // }

  // .text-field {
  //   width: 100%;
  //   font-family: $font-title;
  //   // background-color: $primary-background;
  //   .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  //     color: black;
  //     font-family: $font-title;
  //     // border-color: #fff;
  //   }
  //   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
  //     color: black;
  //     font-family: $font-title;
  //     border-radius: 10px;
  //   }
  //   .MuiInputLabel-root.Mui-focused {
  //     color: black;
  //     font-family: $font-title;
  //   }
  // }

  .action-container {
    width: 100%;
    margin: 10px 16px 10px 16px;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-create {
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
    }
  }
  .btn-print {
    background-color: $background-create !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
  }
}
