@import "../themes.scss";

.overtime-container {
  width: 100%;
  .page-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
  }

  .btn-add {
    background-color: $background-create !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    font-size: $font-size-body;
  }

  .btn-add-list {
    padding: 4px 10px;
    border-radius: 10px;
    background-color: $background-create !important;
    color: #fff;
  }

  .text-field {
    width: 100%;
    border-radius: 10px;
    font-family: $font-title;
    background-color: #fff;
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-title;
      border-radius: 10px;
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
  }

  .body-container {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    .tabs-title {
      font-family: $font-title;
      font-size: $font-size-body;
    }

    .user-request {
      font-size: $font-size-body;
    }

    .date-request {
      font-size: 12px;
    }
  }
}
