@import "../themes.scss";

.drawer-container {
  padding: 10px 20px;
  height: 100%;

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
  }

  .close-icon-container {
    background-color: $primary-background;
    .close-icon {
      // color: red;
      color: $primary-color;
    }
  }

  .field-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .avater-image {
    width: 140px;
    height: 140px;
  }

  .action-container {
    width: 100%;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-create {
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
    }
  }

  //card container
  .drawer-card-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .request-card-header {
      margin-top: 4px;
      background-color: #f0f1fc;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid $primary-color;
    }

    .request-card-warning {
      margin-top: 4px;
      background-color: #fbf9d1;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid #ddd200;
    }
    .request-card-take-leave-record {
      margin-top: 4px;
      background-color: #e7f6e6;
      display: flex;
      padding-left: 10px;
      border-left: 4px solid #01ba36;
    }

    .request-card-request-type {
      font-size: 13px;
      font-weight: bold;
      font-family: $font-title;
    }

    .request-card-reason {
      font-size: 13px;
      font-family: $font-title;
    }

    .request-card-item {
      font-family: SiemReap !important;
      font-weight: 100 !important;
      font-size: 13px;
    }

    .take-leave-card-mobile {
      color: #023f88;
      font-size: 14px;
      font-weight: bold;
      background-color: #ffff;
      border-radius: 4px;
      width: 100%;
      padding: 20px 20px;
      display: flex;
      justify-content: left;
    }

    .take-leave-card-reason {
      color: black !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      font-family: Siemreap !important;
      padding-bottom: 15px !important;
    }

    .btn-dennie {
      background-color: $background-delete !important;
      color: #fff;
      border-radius: 10px;
      text-transform: none;
      padding: 4px 10px;
    }
    .btn-approve {
      background-color: $background-create !important;
      color: #fff;
      border-radius: 10px;
      text-transform: none;
      padding: 4px 10px;
    }
  }
}
