// -----------Color
$primary-color: #082137;
$primary-background: #EBEDEF;
$background-menu-active: #2B5C8E;
$background-table-active: #F4F6F6;
// $background-create: #2070CB;
// $background-create: #1A71E3;
$background-create: #128A43;
$background-delete: #F26469;

//font
$font-title: "Century Gothic";
// Microsoft JhengHei, Century Gothic
$font-body: "Siemreap" !important;
$font-kh-bayon: "Bayon" !important;

//font-size
$font-size-title: 22px;
$font-size-subtitle: 18px;
$font-size-body: 15px;
// Login Page

@mixin desktop {
  @media (min-width: #{1025px}) and (max-width: #{1650px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{769px}) and (max-width: #{1024px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{426px}) and (max-width: #{768px}) {
    @content;
  }
}

@mixin mobile-large {
  @media (min-width: #{426px}) and (max-width: #{600px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{377px}) and (max-width: #{425px}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width : #{0px}) and (max-width: #{376px}) {
    @content;
  }
}
