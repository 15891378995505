@import "../themes.scss";

.small-menu {
  .MuiDrawer-paper {
    background-color: $primary-color;
  }

  .image-container {
    .logo {
      width: 50px;
      height: 50px;
    }
  }

  .list-menu {
    .list-item-active {
      width: 100%;
      height: 50px;
      background-color: $background-menu-active;

      .list-item-button {
        height: 100%;
        display: flex;
        justify-content: center;
        .icon {
          width: 20px;
          height: 20px;
          color: #fff;
        }
      }
    }

    .list-item {
      width: 100%;
      height: 50px;
      background-color: $primary-color;

      .list-item-button {
        height: 100%;
        display: flex;
        justify-content: center;

        .icon {
          width: 20px;
          height: 20px;
          color: #fff;
        }
      }
    }
  }

  .stack-footer {
    padding-bottom: 20px;

    .icon-footer {
      color: #dfdfdf;
      font-size: 30px;
    }
  }
}
