@import "../../../themes.scss";

.preview-pdf-dialog {

  .container {
    display: flex;
    justify-content: center;
    background-color: $background-table-active;
    width: 100%;

    .document{
      background-color: red !important;
    }
  }

  .btn-upload{
    background-color: $primary-color !important;
    color: #fff;
    width: 100%;
    padding: 8px 60px;
    border-radius: 5px;
  }
}
