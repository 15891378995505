@import "../themes.scss";

.login-page {
  width: 100%;
  height: 100vh;
  background-color: $primary-background;

  .right-page-container {
    width: 100%;
    background: linear-gradient(
      45deg,
      rgba(110, 213, 162, 1) 0%,
      rgba(30, 98, 64, 1) 100%
    );
    height: 100vh;
    position: relative;

    .vertical-center-leftSide {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -47%);
    
      .company-title {
        font-size: $font-size-title;
        color: #fff;
        text-align: center;
      }
      .powered-by-container{
        padding-top: 5vh;
        .powered-by {
          font-size: $font-size-title;
          color: #e0dede;
          text-align: center;
          font-size: 14px;
          padding-top: 5px;
        }
      }

    }
  }
  .vertical-center-rightSide {
    margin-top: 50px;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .login-text{
      font-size: $font-size-title;
      font-weight: bold;
      color: gray;
      text-align: center;
    }
    .login-des{
      font-size: $font-size-body;
      color: gray;
      text-align: center;
    }
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .text-field {
    background-color: #fff;
    border-radius: 6px;

    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    // :hover .MuiOutlinedInput-input {
    //   color: #fff;
    // }
    // :hover .MuiInputLabel-root {
    //   color: black;
    // }
    // :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    //   border-color: #fff;
    // }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .link-forgot{
    font-size: $font-size-body;
    // font-weight: bold;
    color: gray;
    // opacity: 80%;
  }

  .btn-login{
    width: 100%;
    background-color: $background-create !important;
    color: #fff;
  }
}
