@import "../../themes.scss";

.empty-data {
  height: 300px;
  .empty-icon {
    color: $primary-color;
    font-size: 50px;
  }
  .titleEn {
    padding: 10px;
    border: none;
    font-family: $font-title;
    font-size: $font-size-subtitle;
    font-weight: bold;
    text-align: center;
    color: $primary-color;
  }

  .subEn {
    font-family: $font-title;
    font-size: $font-size-subtitle;
  }
}
