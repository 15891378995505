@import "../themes.scss";

.more-icon {
  color: $primary-color;
}

.update-icon {
  color: $background-create;
  font-size: 20px !important;
}

.delete-icon {
  color: $background-delete;
  font-size: 21px !important;
}

.update-title {
  font-family: $font-title;
  font-size: $font-size-body !important;
}