@import "../themes.scss";

.top-navbar {
  background-color: #fff;

  .iconbtn-mobile {
    background-color: $primary-background !important;
  }

  .profile-container {
    background-color: gray;

    .avatar {
      width: 50px;
      height: 50px;
    }
  }

  .user-container {
    text-transform: none;
    color: gray;

    .user-name {
      font-family: $font-body;
      font-size: $font-size-body;
      text-decoration: none;
    }
  }


  .css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: gray !important;
  }
}