.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: GothicBoldRegular;
  src: url("../Assets/font/GothicBoldRegular.ttf");
}

@font-face {
  font-family: Century;
  src: url("../Assets/font/Century.ttf");
}

@font-face {
  font-family: KhmerOsMuol;
  src: url("../Assets/font/KhmerOSmuollight.ttf");
}

@font-face {
  font-family: Siemreap;
  src: url("../Assets/font/KhmerOSsiemreap.ttf");
}

@font-face {
  font-family: Bayon;
  src: url("../Assets/font/Bayon.ttf");
}

/* ============================================= Menu change font ============================================ */

.menuKh {
  font-family: Siemreap !important;
}

.menuEn {
  font-family: Century Gothic !important;
  /* font-weight: bold !important; */
}

.laguage-title {
  font-family: Century Gothic !important;
  /* font-weight: bold !important; */
  color: rgb(17, 17, 17) !important;
}

.username-login {
  color: #38b14a !important;
  text-transform: none !important;
  font-family: Century Gothic !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

/* ==================================== Daily officer report ============================= */

.setup-report-container {
  padding: 20px 40px 40px 40px;
}

.logo-company {
  width: 140px;
  height: auto;
}

.report-top-title {
  margin-top: 20px;
  text-align: center !important;
  font-size: 14px !important;
  color: #000 !important;
  font-family: KhmerOsMuol !important;
  font-weight: 100 !important;
}

.report-top-title-head {
  margin-top: 20px;
  text-align: center !important;
  font-size: 14px !important;
  color: #000 !important;
  font-family: Century !important;
  font-weight: bold !important;
}

.report-top-subtitle-head {
  text-align: center !important;
  font-size: 12px !important;
  color: #000 !important;
  font-family: Century !important;
  font-weight: bold !important;
}

.createdby-name {
  font-size: 12px !important;
  color: #000 !important;
  font-family: Century !important;
}

/* .daily-officer-table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.daily-officer-table tr th {
  border: 1px solid #000;
  font-family: Siemreap !important;
  padding: 4px;
  font-size: 11px;
  font-weight: 100 !important;
  color: #000;
}

.daily-officer-table tr td {
  border: 1px solid #000;
  text-align: left !important;
  padding: 2px;
  font-size: 11px;
  color: #000;
  font-family: Siemreap !important;
} */

.takeleave-des {
  font-family: Siemreap !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: #000;
}

.takeleave-item {
  font-family: Siemreap !important;
  font-size: 12px !important;
  font-weight: bold !important;
  /* color: red; */
}

.takeleave-des-center {
  text-align: center !important;
  font-family: Siemreap !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: #000;
  font-weight: bold !important;
}

.takeleave-des-footer {
  text-align: center !important;
  font-family: KhmerOsMuol !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: #000;
}

.takeleave-des-footer-eng {
  text-align: center !important;
  font-family: KhmerOsMuol !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #000;
}

/* ===================== summary table report ==================== */
.summary-table-report {
  width: 100%;
  border-collapse: collapse;
}
.summary-table-report tr th {
  border: 1px solid #000;
  background-color: green;
  font-family: Siemreap !important;
  padding: 4px;
  font-size: 11px;
  font-weight: bold !important;
  color: #fff;
}

.summary-table-report tr td {
  border: 1px solid #000;
  text-align: center;
  padding: 2px;
  font-size: 11px;
  color: #000;
  font-family: Siemreap !important;
}

/* .summary-table-report tr:nth-child(4n + 2) {
  background-color: rgb(227, 227, 227) !important;
} */
/* ======================== Print staff information =========================== */
.print-staff-info-profile-container {
  background-color: rgb(50, 50, 71);
  padding: 40px 24px;
  height: 1120px !important;
}
.print-staff-info-image {
  width: 160px !important;
  height: 160px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.print-staff-info-title {
  font-size: 15px !important;
  font-weight: bold !important;
  font-style: italic !important;
  color: #fff;
}
.print-staff-info-item {
  font-size: 12px !important;
  color: #fff;

  /* line-height: 1.5em;
  width: 200px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis; */
}
.print-staff-info-item-bold {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #fff !important;
  font-style: italic !important;
}
.print-staff-info-name-container {
  padding: 20px 25px;
}
.print-staff-info-staff-name {
  font-size: 30px !important;
  font-weight: bold !important;
}
.print-staff-info-staff-positions {
  font-size: 20px !important;
  /* font-weight: 540 !important; */
}
.print-staff-name-title {
  font-size: 15px !important;
  font-weight: bold !important;
  font-style: italic !important;
  color: rgb(50, 50, 71);
}
.print-staff-name-item {
  font-size: 12px !important;
  color: rgb(50, 50, 71);
}
.print-staff-name-item-bold {
  font-size: 12px !important;
  font-weight: bold !important;
  color: rgb(50, 50, 71) !important;
  font-style: italic !important;
}
