@import "../themes.scss";

.menu-mobile {
  .MuiDrawer-paper {
    background-color: $primary-color;
  }

  .image-container {
    .logo {
      width: 60px;
      height: 60px;
    }
  }

  .drawer-header {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
  }

  .list-menu {
    .list-item-active {
      width: 100%;
      height: 44px;
      background-color: $background-menu-active;

      .list-item-button {
        height: 100%;
        display: flex;
        .icon {
          width: 20px;
          height: 20px;
          color: #fff;
          margin-left: 20px;
        }
        .icon-submenu {
          width: 20px;
          height: 20px;
          color: #fff;
          margin-left: 40px;
        }
        .list-item-text {
          font-size: $font-size-body;
          font-family: $font-title;
          color: #fff;
          margin-left: 15px;
        }
      }
    }

    .list-item {
      width: 100%;
      height: 44px;
      background-color: $primary-color;

      .list-item-button {
        height: 100%;
        display: flex;

        .icon {
          width: 20px;
          height: 20px;
          color: #fff;
          margin-left: 20px;
        }
        .icon-submenu {
          width: 20px;
          height: 20px;
          color: #fff;
          margin-left: 40px;
        }
        .list-item-text {
          font-size: $font-size-body;
          font-family: $font-title;
          color: #fff;
          margin-left: 15px;
        }
      }
    }
  }

  .powered-by {
    color: rgb(152, 151, 151);
    font-size: 13px;
    text-align: center;
    // padding-bottom: 5px;
  }
}
