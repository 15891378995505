@import "../../themes.scss";

.crop-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-body;
    color: $primary-color;
    font-weight: bold;
  }

  .close-icon {
    color: red;
  }

  .avater-image {
    width: 140px;
    height: 140px;
  }

  .dialog-content {
    background: #fff !important;
    position: relative;
    height: 500px;
    width: auto;
  }

  .dialog-driverlicense { 
    background: #fff !important;
    position: relative;
    height: 450px !important;
    width: 600px !important;

    .crop-container{
      width: 100%;
      height: 100%;
    }
  }
}
